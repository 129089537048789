export const updatePhonepattern = (value: string) => {
    // Ensure value is a string and strip out any non-digit characters
    let input = value ? String(value).replace(/\D/g, '') : '';
  
    // Format the phone number based on its length
    if (input.length > 3 && input.length <= 6) {
      input = `(${input.slice(0, 3)}) ${input.slice(3)}`;
    } else if (input.length > 6) {
      input = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
    } else if (input.length === 0) {
      input = '';
    } else if (input.length <= 3) {
      input = `(${input.slice(0, 3)}`;
    }
  
    return input;
  };

  export const fillMonthValues = () => {
    return Array.from({ length: 12 }, (_, i) => {
      const month = String(i + 1).padStart(2, '0');
      return { name: month, id: month };
    });
  };
 
export const extractDomain = (email: string) => {
  let parts = email.split('@');
  return parts.length === 2 ? parts[1] : null;
};
export const isValidDomain = (email: string, domain = 'parsonskellogg.com') => {
  return extractDomain(email) === domain;
};

  
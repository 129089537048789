//////////////////////////////////////////////////////////////////////

export const _Store = {
  type1: 'CG',
  type2: 'GG',
  type3: 'PKHG',
  type4: 'DI',
  type5: 'UHP',
  type6: 'BB',
  type10: 'Uniti',
  type11: 'PORSCHE',
};

export const _StoreDomains = {
  // domain1: 'corporategear',
  // domain1: 'cg.headlessmart.com',
  domain1: 'cg.redefinecommerce.info',
  domain2: 'gamedaygear.info',
  domain3: 'pkhealthgear.online',
  // domain3: 'pkhealthgear.redefinecommerce.info',
  domain4: 'www.drivingi.com',
  domain5: 'corporategear.online',
  domain6: 'bacarditogo.headlessmart.com', // CLOSED
  domain7: 'bbcprod.redefinecommerce.info',
  domain8: 'bain.redefinecommerce.info',
  domain9: 'bcorp.redefinecommerce.info', // CLOSED
  domain10: 'gilbaneco',
  domain11: 'https://cyxtera.redefinecommerce.info',
  domain12: 'healthyPoints', // DUPLICATE
  domain13: 'bacardi',
  domain14: 'humanaheroes', // CLOSED
  domain15: 'petermillar.headlessmart.com',
  domain16: 'southerntide.headlessmart.com',
  domain17: 'theHardFord', // CLOSED
  domain18: 'theHardFordClaimsapparel', // CLOSED
  domain19: 'theHardFordJuniorfireMarshal', // CLOSED
  domain20: 'theHardFordVolshirt', // CLOSED
  domain21: 'corporategear.online',
  // domain21: 'cg.redefinecommerce.info',
  domain22: 'humanadev.parsonskellogg.com',
  domain23: 'ussaClaimsApparel',
  domain24: 'ussaMilitarysAffairs',
  domain25: 'wayFair', // CLOSED
  domain26: 'healthypoints',
  domain27: 'bainCapital',
  domain28: 'bc.redefinecommerce.info',
  domain29: 'pkhealthgear.redefinecommerce.info',
};

////////////////////////////////////////////////////////////////////// bb
//////////////////////////////////////////////////////////////////////
/*                  DEV ENVIORNMENT SETTINGS                        */
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

export const __domain = {
  isSiteLive: 'false',
  localDomain: 'www.drivingi.com',
};

console.log(__domain);

export const defaultCountry = 1;
export const storeBuilderTypeId = 4;
export const defaultState = 1;

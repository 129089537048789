'use client';

import { useTypedSelector_v2 } from '@hooks_v2/index';
import React from 'react';
interface _props {
  children: React.ReactNode;
}

interface _SpinnerProps {
  showCheckoutLoader?: boolean;
}

export const SpinnerComponent = ({
  showCheckoutLoader = false,
}: _SpinnerProps) => {
  return (
    <div id='root' className=''>
      <div className='w-full inline-flex flex-wrap jutify-center flex-col h-screen justify-center gap-y-5'>
        <div className='loader-wrapper !inline-flex flex-col justify-center gap-y-5'>
          {showCheckoutLoader && (
            <div className='text-2xl text-center font-bold'>
              Please wait while we process your order. Do not refresh this page
              or hit the back button.
            </div>
          )}
          <div className='loader'></div>
        </div>
      </div>
    </div>
  );
};

const Spinner: React.FC<_props> = ({ children }) => {
  const showLoader = useTypedSelector_v2((state) => state.loader.showLoader);
  return (
    <>
      {showLoader ? <SpinnerComponent /> : null}
      {children}
    </>
  );
};

export default Spinner;
